import useSWR from 'swr';

import { getTrustpilotScore } from 'mycs/api/UserAPI';

export function useTrustpilotScore() {
  const { data, error, isLoading } = useSWR(
    'getTrustpilotScore',
    () => getTrustpilotScore(),
    {
      revalidateOnFocus: false,
    }
  );

  return {
    companyScore: {
      stars: data?.score.stars || 0,
      trustScore: data?.score.trustScore || 0,
      reviews: data?.numberOfReviews.total || 0,
    },
    isLoading,
    isError: error,
  };
}
